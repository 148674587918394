import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {LoginComponent} from "./pages/login/login.component";
import {PagesComponent} from "./pages/pages.component";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {SharedModule} from "./shared/shared.module";
import {OverlayModule, OverlayService} from "@clavisco/overlay";
import {MenuModule} from "@clavisco/menu";
import {Miscellaneous} from "@clavisco/core";
import {AppInterceptor} from "./interceptors/app-interceptor";
import {ActionCenterPushInterceptor} from "./interceptors/action-center-push.interceptor";
import {LoginModule} from "@clavisco/login";
import {RECAPTCHA_V3_SITE_KEY} from "ng-recaptcha";
import {environment} from "../environments/environment";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {TokenInterceptor} from "./interceptors/token-interceptor";
import {ErrorInterceptor} from "./interceptors/error.interceptor";
import {TableModule} from "@clavisco/table";
import {CurrencyPipe} from "@angular/common";
// import {PagesModule} from "@clavisco/pages";


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PagesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    LoginModule,
    MenuModule,
    OverlayModule,
    TableModule,
    // PagesModule
  ],
  providers: [
    {
      provide: 'OverlayService',
      useClass: OverlayService
    },
    { provide: HTTP_INTERCEPTORS, useClass: ActionCenterPushInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Miscellaneous.Interceptors.HttpAlertInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Miscellaneous.Interceptors.PagedRequestInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recatpchaSiteKey
    },
     CurrencyPipe,
],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
